/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				setTimeout(function(){ 
					$('.amazingslider-wrapper-1 .amazingslider-slider-1 .amazingslider-box-1 div:nth-child(3)').css('display','block');
				}, 3000);

				$('.fancybox').fancybox({
					autoSize: false,
					'width':1000,
					afterShow: function () {
						console.log(this.href + ' .flexslider');
						$(this.href + ' .flexslider.flex-popup').flexslider({
							animation: "slide",
		    				controlNav: "thumbnails"
						});
					}
				});

				var hash = window.location.hash;
				//If the hash exists on the page and its connetced to a modal-window, try to open it
				if( hash.indexOf('/')===-1 && (hash.length>0) ){
					if( ($(hash).length) ){

						$.fancybox([ 
							{
								href 	 : hash,
								autoSize : false,
								width 	 : 1000,
								afterShow: function () {
									$(hash + ' .flexslider.flex-popup').flexslider({
										animation: "slide",
					    				controlNav: "thumbnails"
									});
								}
							}
						]);

					}
				}


				$(".bar").click(function() {
					$('.menu-collapsed').toggleClass("menu-expanded");
					$('.mobile-lang').toggleClass("hide");
					$('body').toggleClass('search-open');
					$(".nav>li>a").each(function(i, li) {

					var $list = $(this).closest('ul');
						$list.queue(function() {
							$(li).toggleClass('animation');
							$list.dequeue();
						}).delay(150);
					});
				});
				$('.flexslider.flex-start').flexslider({
					animation: "slide",
				});

				var navFixedTop = false;
				$(window).scroll(function () {
					var navbarOffsetTop = 0;
					if($(window).width() > 767)
					{
						navbarOffsetTop = 115;
						if (!navFixedTop && $(window).scrollTop() > navbarOffsetTop) {
							$('.banner').addClass('navbar-mini');
							$('#search').addClass('nav-mini');
							// $('.offset').addClass('offset-mini');
							navFixedTop = true;
						}
						else if(navFixedTop && $(window).scrollTop() <= navbarOffsetTop){
							$('.banner').removeClass('navbar-mini');
							$('#search').removeClass('nav-mini');
							// $('.offset').removeClass('offset-mini');
							navFixedTop = false;
						}
					}
				});

				$('a[href="#search"]').on('click', function(event) {
					$('#search').addClass('open');
					$('body').addClass('search-open');
					if($(document).width() > 992){
						setTimeout(function(){
							$('#search input[type="search"]').focus();
						}, 500);
					}
				});

				var reallyFinished = {}
				function delayCallback (name, callback, time) {
					if (time === undefined) {
						time = 300
					}
					if (reallyFinished[name] !== undefined && reallyFinished[name] !== null) {
						clearTimeout(reallyFinished[name])
						reallyFinished[name] = null
					}
					reallyFinished[name] = setTimeout(function () { reallyFinished[name] = null; callback() }, time)
				}

				$(document).on('click keyup', '#search, #search button.close', function(event) {
					if(event.keyCode === 13)
					{
						$('.loader-blog-product-search').toggleClass('hide');
						search_blog();
						search_product_();
					}
					if(event.target.className === 'btn-primary btn btn-borderd btn-more-posts')
					{
						$('.loader-blog-search').toggleClass('hide');
						search_blog();
					}
					if(event.target.className === 'btn-primary btn btn-borderd btn-more-products')
					{
						$('.loader-product-search').toggleClass('hide');
						search_product_();
					}

					if(event.target === this || event.target.className === 'close' || event.keyCode === 27) {
						$(this).removeClass('open');
						$('body').removeClass('search-open');
					}
				});

				$(document).on('keyup', '#search .asdf', function(event) {
					delayCallback('delay', function(){
						$('.loader-blog-product-search').toggleClass('hide');
						search_blog();
						search_product_();
					}, 300);
					
				});

				$(document).on('click', '.btn-more-prod', function(event) {
					$('.loader-product-brand').toggleClass('hide');
					var s = parseInt($('.btn-more-prod').attr('data-nr-products'));
					s = s + 6;
					var pid = parseInt($('.output-products').attr('data-post-id'));
					var lang = $( '#header' ).attr( 'data-lang' );

					var data = {action: 'output_products', s:s, pid:pid, lang:lang};
					jQuery.post(ajaxurl, data, function(response) {
						if( response ){
							jQuery('.output-products .row').remove();
							jQuery('.output-products').append(response);
						}
					});

					$('.btn-more-prod').attr('data-nr-products', s);
				});

				function search_blog()
				{
					s_query = $('.asdf').val();
					var s = parseInt($('.btn-more-posts').attr('data-nr-posts'));
					s = s + 6;
					if(s === 'undefined')
					{
						s = 6;
					}
					var lang = $( '#header' ).attr( 'data-lang' );
					var data = {action: 'search', s:s, s_query:s_query, lang: lang};
					jQuery.post(ajaxurl, data, function(response) {
						jQuery('.search-block .post-row').remove();
						jQuery('.search-block').append(response);
					});

					$('.btn-more-posts').attr('data-nr-posts', s);
				}

				function search_product_()
				{
					s_query = $('.asdf').val();
					var s = parseInt($('.btn-more-products').attr('data-nr-posts'));
					s = s + 6;
					if(s === 'undefined')
					{
						s = 6;
					}
					var lang = $( '#header' ).attr( 'data-lang' );
					var data = {action: 'search_product', s:s, s_query:s_query, lang:lang};
					jQuery.post(ajaxurl, data, function(response) {
						jQuery('.search-block .product-row').remove();
						jQuery('.search-block').prepend(response);
					});

					$('.btn-more-products').attr('data-nr-posts', s);
				}

				$(document).on('click', '.btn-blog', function(event) {
					$('.loader-blog-start').toggleClass('hide');
					var s = parseInt($('.btn-blog').attr('data-nr-posts'));
					s = s + 4;
					if(s === 'undefined')
					{
						s = 4;
					}
					var data = {action: 'output_blog_posts', s:s};

					jQuery.post(ajaxurl, data, function(response) {
						jQuery('.blog-block--bottom>div').remove();
						jQuery('.blog-block--bottom').append(response);
					});
					$('.btn-blog').attr('data-nr-posts', s);
					$(this).remove();
				});

				$(document).on('click', '.btn-prod-blog-news', function(event) {
					$('.loader-blog-brand').toggleClass('hide');
					var s = parseInt($('.btn-prod-blog-news').attr('data-nr-posts'));
					var term_id = parseInt($('.output-prod-blog-news').attr('data-term-id'));
					s = s + 2;
					if(s === 'undefined')
					{
						s = 2;
					}
					var lang = $( '#header' ).attr( 'data-lang' );
					var data = {action: 'output_prod_blog_posts', s:s, term_id:term_id, lang:lang };

					jQuery.post(ajaxurl, data, function(response) {
						jQuery('.output-prod-blog-news>div').remove();
						jQuery('.output-prod-blog-news').append(response);
					});
					$('.btn-prod-blog-news').attr('data-nr-posts', s);
					$(this).remove();
				});

				$(document).on('click', '.btn-show-more', function(event) {
					$('.show-more').removeClass('hide');
				});


			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
